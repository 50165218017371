<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <ul v-show="state.nav.sideNav !== false" class="nav nav-pills nav-stacked">
                <li v-if='!state.nav.hideSearch' class="cdi_tab" :class="{'cdi_tab active': 'search' === state.currentView}">
                    <router-link :to="`/${state.nav.base}`" @click.native="setView('search')">
                        {{ state.titlePlural }}
                    </router-link>
                </li>
                <li class="cdi_tab" :class="{cdi_hide: !state.nav.newView}">
                    <router-link :to="`/${state.nav.base}`" @click.native="setView('new')">
                        New {{ state.title }}
                    </router-link>
                </li>
                <li v-for="(view, view_id) in state.nav.views" v-bind:key="view_id" class="cdi_tab" :class="{'cdi_tab active': view.view === state.currentView, cdi_hide: !id && view.alwaysVisible !== true}" >
                    <a @click="setView(view.view)" class="pointer">{{ view.label }}</a>
                </li>
                <li class="cdi_tab" v-show="!state.nav.hideUpload">
                    <a @click="setFileUploadParameters" class="pointer">Upload File</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';

export default {
    name: "Nav",
    data() {
        return {
            state: store.state,
        }
    },
    props: ['id'],
    methods: {
        setView: function(view) {
            this.state.currentView = view;
            this.$appStore.state.fileCallbackView = view;
        },
        setFileUploadParameters: function () {
            let id = this.id;
            let view = this.state.currentView;
            this.$appStore.setFileUploadContext(this.state.name);
            this.$appStore.setFileUploadContextData({
                referenceID: id,
                data: this.state.object.data
            });

            if (typeof this.id !== 'undefined' && this.state.name !== 'cdi_file' && !this.hasCustomFileUploadCallback) {
                this.$appStore.setFileUploadCallback(function () {
                    store.load(id, view);
                });
            } else {
                this.$appStore.setFileUploadCallback(function () {
                    store.state.customFileCallback();
                });
            }
        }
    },
    computed: {
        hasCustomFileUploadCallback: function () {
            return typeof store.state.customFileCallback === 'function';
        }
    }
}
</script>

<style scoped>
.nav-pills{
    padding-bottom: 20px!important;
}
</style>