<template>
    <div v-if="loggedIn">
        <div :class="`fileUploadContainer ${ (expanded) ? 'fileDropzone' : ''}`"
        @drag.prevent
        @drop.prevent="dragUpload($event)"
        @dragover.prevent="dragOver($event)"
        @dragenter.prevent
        @dragleave.prevent="dragLeave($event)"
        @fileDropped="setFileUploadParameters">
            <Nav v-if="!state.hideNav" :id="id" />
            <main :class="mainClass">
                <ol class="breadcrumb" v-show="this.state.currentView !== 'search'" v-if="state.breadcrumbs.length">
                    <li v-for="(crumb, id) in state.breadcrumbs" v-bind:key="id"><a :href="crumb.slug">{{ crumb.label }}</a></li>
                </ol>
                <component v-bind:is="currentComponent" :id="id" :object="currentRoute"></component>
            </main>
        </div>
    </div>
    <div v-else-if="!loggedIn">
        <SignInPage></SignInPage>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store';
import Nav from '@/components/businessObjects/utils/Nav.vue';
import AppStore from "@/store/App.store";
import SignInPage from "@/views/SignInPage";
import {fileUploadStore} from "@/store/FileUpload.store";
import {cdiVars} from "@/cdiVars";

const uploadEndpoints = {
    item_batch: `${cdiVars.API_URL}/cdi_file/upload`
}

export default {
    name: "BusinessObjectPage",
    components: {
        SignInPage,
        Nav,
        asset: () => import('@/components/businessObjects/asset/Container'),
        branch: () => import('@/components/businessObjects/branch/Container'),
        cdi_file: () => import('@/components/businessObjects/file/Container'),
        cdi_location: () => import('@/components/businessObjects/cdi_location/Container'),
        company: () => import('@/components/businessObjects/company/Container'),
        credit: () => import('@/components/businessObjects/credit/Container'),
        curb: () => import('@/components/businessObjects/curb/Container'),
        conforming_stamp: () => import('@/components/tools/ConformingStampManager/Container'),
        inventory_manager: () => import('@/components/tools/InventoryManager/Container'),
        maintenance_log: () => import('@/components/tools/MaintenanceLog/Container'),
        material: () => import('@/components/businessObjects/material/Container'),
        model: () => import('@/components/businessObjects/model/Container'),
        part: () => import('@/components/businessObjects/part/Container'),
        payment: () => import('@/components/businessObjects/payment/Container'),
        po: () => import('@/components/businessObjects/po/Container'),
        corpay_po: () => import('@/components/businessObjects/corpay_po/Container.vue'),
        pricing: () => import('@/components/businessObjects/pricing/Container'),
        region: () => import('@/components/businessObjects/region/Container'),
        shipper: () => import('@/components/businessObjects/shipper/Container'),
        site_request: () => import('@/components/businessObjects/site_request/Container'),
        spring_configuration: () => import('@/components/tools/SpringConfiguration/Container'),
        territory: () => import('@/components/businessObjects/territory/Container'),
        quote: () => import('@/components/businessObjects/quote/Container'),
        supplier: () => import('@/components/businessObjects/supplier/Container'),
        timecard: () => import('@/components/businessObjects/timecard/Container'),
        tracker: () => import('@/components/businessObjects/tracker/Container'),
        tracker_mer: () => import('@/components/businessObjects/tracker_mer/Container'),
        user: () => import('@/components/businessObjects/user/Container'),
        tracker_curb: () => import('@/components/businessObjects/tracker_curb/Container'),
    },
    data() {
        return {
            state: store.state,
            appState: this.$appStore.state,
            callback: null,
            filestate: fileUploadStore.state,
            url: uploadEndpoints[this.fileUploadContext],
            expanded: false,
            showModal: false,
            files: []
        }
    },
    methods: {
    dragUpload: function(e) {
        this.$emit('fileDropped');

        // Collapse the file upload zone
        this.expanded = false;

        // Looping through the single instance of a file to extact data.
        // Populate metadata about the file itself in the stores.
        // Execute file submission
    if(!this.filestate.isModal){
        if (e.dataTransfer.files.length) {
            this.files = [];

            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                this.files.push(e.dataTransfer.files[i]);
            }
            fileUploadStore.setFile(this.files[0]);
            fileUploadStore.setTitle(this.files[0].name);
            this.submitFiles();
        } else {
            this.$appStore.errorBox('No file detected.');
        }
    }
        this.filestate.isModal = false;
    },
    submitFiles(){
        fileUploadStore.uploadFile();
        this.setFileUploadParameters();
    },
    dragOver: function(e) {
        e.stopPropagation();
        this.expanded = true;
    },
    dragLeave: function(e) {
        e.stopPropagation();
        this.expanded = false;
    },
        setFileUploadParameters: function () {
            let id = this.id;
            this.$appStore.setFileUploadContext(this.state.name);
            this.$appStore.setFileUploadContextData({
                referenceID: id,
                data: this.state.object.data
            });

            if (typeof this.id !== 'undefined' && this.state.name !== 'cdi_file' && !this.hasCustomFileUploadCallback) {
                this.$appStore.setFileUploadCallback(function () {
                    store.load(id);
                });
            } else {
                this.$appStore.setFileUploadCallback(function () {
                    store.state.customFileCallback();
                });
            }
        }
    },
    computed: {
        currentRoute() {
            return this.$route.name;
        },
        currentComponent: function() {
            return this.$route.name;
        },
        loggedIn: function() {
            return this.appState.loggedIn
        },
        componentViewOverride: function() {
            return this.$appStore.state.synchronizeFileCallbackView;
        },
        hasCustomFileUploadCallback: function () {
            return typeof store.state.customFileCallback === 'function';
        },
        mainClass: function() {
            if (this.state.hideNav) {
                return 'col-sm-12';
            } else {
                return 'col-lg-10 col-md-9 col-sm-9 left_gutter fill main';
            }
        }
    },
    props: ['id'],
    watch: {
        id: function () {
            store.state.customFileCallback = null;
        },
        currentComponent: function() {
            this.state.breadcrumbs = []
            if (this.currentComponent == 'corpay_po') {
                this.state.hideNav = true;
            } else {
                this.state.hideNav = false;
            }
        },
        componentViewOverride: function(override, dormant) {
            if (!this.state.currentView == 'new') {
                if (override && !dormant && AppStore.state.fileCallbackView) {
                    this.state.currentView = '';
                    let scope = this;
                    setTimeout(function () {
                        scope.state.currentView = AppStore.state.fileCallbackView;
                    }, 1);
                    AppStore.state.synchronizeFileCallbackView = false

                }
            }
        }

    }
}
</script>

<style scoped>
.fileUploadContainer {
    height: 80%;
    width: 100%;
}

.fileDropzone {
    opacity: .5;
}


</style>
